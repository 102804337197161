<template>
  <div>
    <h2 class="mb-4">Логи действий</h2>

    <logs-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table url="logs" :headers="headers" :table-params="tableParams" clickable-rows @click:row="showLog">
      <template v-slot:[`item.action`]="{ item: log }">
        <nobr>{{ log.action }}</nobr>
      </template>

      <template v-slot:[`item.userName`]="{ item: log }">
        <router-link class="nobr" :to="{ name: 'users-id', params: { id: log.userId } }">
          {{ log.userName || "не указано" }}
        </router-link>
      </template>

      <template v-slot:[`item.userRole`]="{ item: log }">
        {{ formatRole(log) }}
      </template>

      <template v-slot:[`item.statusCode`]="{ item: log }">
        <v-chip :color="pickHttpStatusColor(log)">
          {{ log.statusCode }}
        </v-chip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item: log }">
        <nobr>{{ log.createdAt | dateTime }}</nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { UserRole } from "~/libs/user/user-role";
import { HttpCode } from "~/libs/http-code";
import { editorForm } from "~/mixins/multi-editor/editor-form";

import LogsFilters from "~/components/logs/index/filters";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "Действие", value: "action" },
        { text: "Пользователь", value: "userName" },
        { text: "ID пользователя", value: "userId" },
        { text: "Роль", value: "userRole" },
        { text: "HTTP статус", value: "statusCode" },
        { text: "Создано", value: "createdAt" }
      ],

      tableParams: {
        action: "",
        userName: "",
        userId: "",
        statusCode: ""
      }
    };
  },
  methods: {
    showLog({ id }) {
      this.$router.push({ name: "logs-id", params: { id } });
    },

    formatRole({ userRole }) {
      return new UserRole(userRole).text;
    },

    pickHttpStatusColor({ statusCode }) {
      return new HttpCode(statusCode).color;
    },

    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    LogsFilters
  }
};
</script>
