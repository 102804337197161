<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field label="Действие" :value="tableParams.action" clearable @input="setField('action', $event)" />
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          label="Имя пользователя"
          :value="tableParams.userName"
          clearable
          @input="setField('userName', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          label="ID пользователя"
          :value="tableParams.userId"
          clearable
          @input="setField('userId', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          label="HTTP статус"
          :value="tableParams.statusCode"
          clearable
          @input="setField('statusCode', $event)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  }
};
</script>
